import api from "../index";

export default {
  async update(id, blocks) {
    return api.put(`/admin/landing_pages/${id}/design`, {
      blocks: JSON.stringify(blocks)
    });
  },
  async renderFoldDown(body) {
    return api.post("/admin/landing_pages/generate_fold_down", { body });
  },
  async createFoldDownImage(formData) {
    return api.post("/user_photos", formData);
  },
  async fetchProducts(ids) {
    return api.post("/admin/landing_pages/fetch_products", { ids });
  },
  async searchProducts(term, filters, storeId) {
    return api.post("/admin/landing_pages/search_products", {
      term,
      filters,
      store_id: storeId
    });
  },
  async fetchCategoryProducts(categoryId, filters, storeId) {
    return api.post("/admin/landing_pages/fetch_category_products", {
      category_id: categoryId,
      filters,
      store_id: storeId
    });
  },
  async renderProductCarousel({ ids, sizes }) {
    return api.post("/admin/landing_pages/render_product_carousel", {
      ids,
      sizes
    });
  }
};
